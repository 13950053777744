/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
  useFormDuuf,
} from 'components/related/Form'

// Interface
import { FormProps } from 'components/flex/Form/FormShell'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;
  background: ${({ theme }) => theme.color.light};

  & .form-duuf-group {
    margin-bottom: 2rem;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: #f2f2f2;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    padding: 10px 20px;
    background-color: #f2f2f2;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: green;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const FormWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.light};
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 36px;
    & strong {
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
    }
  }
`

const Title = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    opacity: 0.1;
    & strong {
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
    }
    @media (min-width: 1400px) {
      font-size: 100px;
    }
    @media (max-width: 1399px) {
      font-size: 80px;
    }
    @media (max-width: 1199px) {
      font-size: 70px;
    }
    @media (max-width: 767px) {
      font-size: 40px;
    }
    @media (max-width: 575px) {
      font-size: 30px;
    }
  }
`

const Section = styled.section`
  @media (min-width: 992px) {
    margin-bottom: 4rem;
  }
`

// const CheckWrapper = styled.div`
//   font-size: 20px;
//   font-weight: 700;
//   font-family: ${({ theme }) => theme.font.family.primary};
//   color: ${({ theme }) => theme.color.primary};
// `

const FormInvoice: React.FC<FormProps> = ({ fields }) => (
  <Section id={fields.sectionid}>
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <div className="row">
            <div className="col-lg-12">
              {/* <Title content={fields.formdescription} className="mb-3" /> */}

              <FormWrapper className="p-lg-5 p-4">
                <Content content={fields.formdescription} className="mb-5" />
                <StyledFormDuuf
                  id={4}
                  generate={false}
                >
                  <InnerForm />
                </StyledFormDuuf>
              </FormWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Section>
)

function InnerForm() {
  const form = useFormDuuf()

  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <FormDuufGroup showIds={[1]} />
        </div>
        <div className="col-lg-6">
          <FormDuufGroup showIds={[3]} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <FormDuufGroup showIds={[15]} />
        </div>
        <div className="col-lg-6">
          <FormDuufGroup showIds={[16]} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <FormDuufGroup showIds={[13]} />
        </div>
        <div className="col-lg-6">
          <FormDuufGroup showIds={[4]} />
        </div>
      </div>
      <div className="row">
        <FormDuufGroup showIds={[7]} />
      </div>
      <div className="row">
        <FormDuufGroup showIds={[8]} />
      </div>
      <div className="row">
        <FormDuufGroup showIds={[17, 20, 21]} />
      </div>
      <div className="row">
        <div className="col-lg-6">
          <FormDuufSubmit />
        </div>
      </div>
    </>
  )
}

export default FormInvoice
